var trackService = {
  session(id, userName) {
    if (window.umami)
      window.umami.identify({
        id: id,
        email: userName,
      });
  },
  page(path) {
    if (window.umami) {
      window.umami.track((props) => ({ ...props, url: path }));
    }
  },
};

export default trackService;
